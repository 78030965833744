<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-link
                :to="{ name: 'cms-pages-new', params: { id: 0 } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <span class="text-nowrap">Create New Page</span>
              </b-link>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchInstructers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(featuredImage)="data">
          <b-media vertical-align="center">
            <template #aside>

              <b-img-lazy
                thumbnail
                width="100"
                height="50"
                :src="data.item.featuredImage"
              />

            </template>

          </b-media>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">

            <span class="align-text-top text-capitalize">{{ data.item.createdAt | formattedDate }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(upperCategory)="data">
          <div class="text-nowrap">

            <span class="align-text-top text-capitalize">{{ Boolean(data.item.Parent)? data.item.Parent.name : '' }}</span>
          </div>
        </template>
        <template #cell(title)="data">
          <b-media vertical-align="center">

            <b-link
              :to="{ name: 'cms-pages-edit',query: { p: data.item.id }, params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.author }}</small>

          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusVariantText(data.item.status) }}

          </b-badge>
        </template>
        <template #cell(isImported)="data">
          <div class="text-nowrap">

            <b-badge
              v-if="data.item.isImported"
              pill
              variant="light-success"
              class="text-capitalize"
            >
              IMPORTED

            </b-badge>
            <b-badge
              v-if="!data.item.isImported"
              pill
              variant="light-secondary"
              class="text-capitalize"
            >
              {{ moment( data.item.createdAt).format('Do MMMM YYYY,  hh:MM ') }}

            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>

              <b-button
                v-if="!data.item.isImported"
                variant=".btn-flat-dark"
                @click="changeCategory(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-button>
            </template>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BImgLazy, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import UsersListFilters from './DepartmentsListFilters.vue'
import useUsersList from './useDepartmentsList'
import userStoreModule from '../departmentStoreModule'

export default {
  filters: {
    formattedDate(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm')
      }
    },
  },
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImgLazy,

    vSelect,
  },
  data() {
    return {
      migrationStarted: false,
      selectedCategory: null, // this.generalData.generalsettings,

    }
  },

  computed: {

  },
  methods: {
    startMigrating() {
      this.migrationStarted = !this.migrationStarted
    },
    changeCategory(e) {
      this.selectedCategory = e
      this.isAddNewUserSidebarActive = true
      console.log(e)
    },

    deletePosition(e) {
      this.selectedCategory = e
      this.isAddNewUserSidebarActive = true
      console.log(e)
    },
    newCategory() {
      this.selectedCategory = {
        id: null,
        name: null,
        description: null,
        upperCategory: null,
        Parent: null,

      }
      this.isAddNewUserSidebarActive = true
    },
    moment() {
      return moment()
    },
  },
  provide() {
    const categorydata = {}

    Object.defineProperty(categorydata, 'selectedCategory', {
      enumerable: true,
      get: () => this.selectedCategory,
    })

    return {

      categorydata,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-pages'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
